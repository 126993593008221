import React from "react";
import {
  BannerPage,
  ContainerContent,
  ContainerCta,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
  Video,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "antd";

const Videos = () => {
  const data = useStaticQuery(graphql`
    query {
      allYoutubeJson {
        nodes {
          youtubeLink
        }
      }
    }
  `);
  const videos = data.allYoutubeJson.nodes;
  return (
    <Layout>
      <SEO
        title="Videos Gallery"
        description={"Browse through our work on Custom homes"}
      />
      <BannerPage
        title={"Videos Gallery"}
        subTitle={"Browse through our work on Custom homes"}
      />
      <ContainerContent>
        <Row gutter={[8, 8]}>
          {videos.map(v => (
            <Col xxl={8} md={12} sm={24} key={v.youtubeLink}>
              <Video url={v.youtubeLink} />
            </Col>
          ))}
        </Row>
        <section style={{ textAlign: "center" }}>
          <InternalLink url={InternalLinkTypes.Disclaimer}>
            See our disclaimer
          </InternalLink>
        </section>
      </ContainerContent>
      <ContainerCta
        title={"Build your dream home with us"}
        primaryText={"Contact us"}
        primaryLink={InternalLinkTypes.Contact}
        secondaryLink={InternalLinkTypes.PhotosGallery}
        secondaryText={"See Photos Gallery"}
      />
    </Layout>
  );
};

export default Videos;
